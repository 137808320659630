import React from 'react';
import { Card, CardContent, Typography, Box} from '@mui/material';
import GraphView from './GraphView';

const HistoryDental = () => {

    
    // let nodes = []
    // for(let i = 0; i < 16; i++) {
    //     nodes.push({id: i, type: "Company", properties: {name: `company ${i}`,}})
    // }

    // const personaExplorationData = {
    //     nodes: nodes,
    //     links: [
    //         {source: 0, target: 3, relationship: "BUYS_FROM"},
    //         {source: 1, target: 3, relationship: "BUYS_FROM"},
    //         {source: 2, target: 3, relationship: "BUYS_FROM"},


    //         {source: 4, target: 7, relationship: "BUYS_FROM"},
    //         {source: 5, target: 7, relationship: "BUYS_FROM"},
    //         {source: 6, target: 7, relationship: "BUYS_FROM"},
    //         {source: 8, target: 7, relationship: "BUYS_FROM"},
    //         {source: 9, target: 7, relationship: "BUYS_FROM"},
    //         {source: 10, target: 7, relationship: "BUYS_FROM"},

    //         {source: 11, target: 15, relationship: "BUYS_FROM"},
    //         {source: 12, target: 15, relationship: "BUYS_FROM"},
    //         {source: 13, target: 15, relationship: "BUYS_FROM"},
    //         {source: 14, target: 15, relationship: "BUYS_FROM"},

    //         {source: 3, target: 7, relationship: "COMPETES_AGAINST"},
    //         {source: 7, target: 3, relationship: "COMPETES_AGAINST"},

    //         {source: 15, target: 7, relationship: "COMPETES_AGAINST"},
    //         {source: 7, target: 15, relationship: "COMPETES_AGAINST"},

    //         {source: 15, target: 3, relationship: "COMPETES_AGAINST"},
    //         {source: 3, target: 15, relationship: "COMPETES_AGAINST"},

    //     ]
    // };
    

    let nodes = []

    const num_companies = 8;
    for(let i = 0; i < num_companies; i++)
        nodes.push({id: i, type: "Company", properties: {name: `Company ${i}`,}})

    for(let i = num_companies; i < num_companies + 12; i++)
        nodes.push({id: i, type: "Person", properties: {name: `Person ${i-num_companies}`,}})

    const similarCompanyGraphData = {
        nodes: nodes,
        links: [
            {source: 0, target: 1, relationship: "COMPETES_AGAINST", curvature: 0.00001},
            {source: 1, target: 0, relationship: "COMPETES_AGAINST", curvature: -0.00001},

            {source: 1, target: 2, relationship: "COMPETES_AGAINST", curvature: 0.00001},
            {source: 2, target: 1, relationship: "COMPETES_AGAINST", curvature: -0.00001},

            {source: 2, target: 0, relationship: "COMPETES_AGAINST", curvature: 0.1},
            {source: 0, target: 2, relationship: "COMPETES_AGAINST", curvature: -0.1},

            {source: 2, target: 0, relationship: "HAS_RELATED_TECH_STACK", curvature: -0.1},
            {source: 0, target: 2, relationship: "HAS_RELATED_TECH_STACK", curvature: 0.1},
            {source: 4, target: 3, relationship: "HAS_RELATED_TECH_STACK", curvature: -0.00001},
            {source: 3, target: 4, relationship: "HAS_RELATED_TECH_STACK", curvature: 0.00001},
            {source: 4, target: 6, relationship: "HAS_RELATED_TECH_STACK", curvature: -0.00001},
            {source: 6, target: 4, relationship: "HAS_RELATED_TECH_STACK", curvature: 0.00001},
            {source: 6, target: 3, relationship: "HAS_RELATED_TECH_STACK", curvature: -0.00001},
            {source: 3, target: 6, relationship: "HAS_RELATED_TECH_STACK", curvature: 0.00001},
            {source: 7, target: 1, relationship: "HAS_RELATED_TECH_STACK", curvature: -0.00001},
            {source: 1, target: 7, relationship: "HAS_RELATED_TECH_STACK", curvature: 0.00001},

            // {source: 6, target: 1, relationship: "HAS_RELATED_TECH_STACK", curvature: 0.00001},
            // {source: 1, target: 6, relationship: "HAS_RELATED_TECH_STACK", curvature: -0.00001},

            {source: 0, target: num_companies + 0, relationship: "HAS_RELEVANT_STAFF_MEMBER", curvature: 0.0},
            {source: 0, target: num_companies + 1, relationship: "HAS_RELEVANT_STAFF_MEMBER", curvature: 0.0},
            {source: 0, target: num_companies + 2, relationship: "HAS_RELEVANT_STAFF_MEMBER", curvature: 0.0},
            {source: 1, target: num_companies + 3, relationship: "HAS_RELEVANT_STAFF_MEMBER", curvature: 0.0},
            {source: 1, target: num_companies + 4, relationship: "HAS_RELEVANT_STAFF_MEMBER", curvature: 0.0},
            {source: 2, target: num_companies + 5, relationship: "HAS_RELEVANT_STAFF_MEMBER", curvature: 0.0},
            {source: 3, target: num_companies + 6, relationship: "HAS_RELEVANT_STAFF_MEMBER", curvature: 0.0},
            {source: 4, target: num_companies + 7, relationship: "HAS_RELEVANT_STAFF_MEMBER", curvature: 0.0},
            {source: 4, target: num_companies + 8, relationship: "HAS_RELEVANT_STAFF_MEMBER", curvature: 0.0},
            {source: 4, target: num_companies + 9, relationship: "HAS_RELEVANT_STAFF_MEMBER", curvature: 0.0},
            {source: 4, target: num_companies + 10, relationship: "HAS_RELEVANT_STAFF_MEMBER", curvature: 0.0},
            {source: 1, target: num_companies + 11, relationship: "HAS_RELEVANT_STAFF_MEMBER", curvature: 0.0},

            {source: num_companies + 0, target: 1, relationship: "HIRED_FROM", curvature: 0.0},
            {source: num_companies + 1, target: 2, relationship: "HIRED_FROM", curvature: 0.0},
            {source: num_companies + 2, target: 3, relationship: "HIRED_FROM", curvature: 0.0},
            {source: num_companies + 3, target: 4, relationship: "HIRED_FROM", curvature: 0.0},
            {source: num_companies + 4, target: 4, relationship: "HIRED_FROM", curvature: 0.0},
            {source: num_companies + 5, target: 4, relationship: "HIRED_FROM", curvature: 0.0},
            {source: num_companies + 6, target: 5, relationship: "HIRED_FROM", curvature: 0.0},
            {source: num_companies + 7, target: 1, relationship: "HIRED_FROM", curvature: 0.0},
            {source: num_companies + 8, target: 2, relationship: "HIRED_FROM", curvature: 0.0},
            {source: num_companies + 9, target: 2, relationship: "HIRED_FROM", curvature: 0.0},
            {source: num_companies + 10, target: 3, relationship: "HIRED_FROM", curvature: 0.0},
            {source: num_companies + 11, target: 2, relationship: "HIRED_FROM", curvature: 0.0},

        ]
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h6">Results</Typography>
                <Box sx={{ position: 'relative', overflow: 'hidden', maxWidth: '100%', height: '800px' }}>
                    <GraphView graphData={similarCompanyGraphData} />
                </Box>
            </CardContent>
        </Card>
    );
};

export default HistoryDental;
