// components/ClientQueue.js
import React, { useState } from 'react';
import { Box, Typography, TextField, Button, List, ListItem, ListItemText, CircularProgress } from '@mui/material';

const ClientQueue = ({ clients, loading, onClientSelect, createNewClient }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filterOption, setFilterOption] = useState('All');

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleFilterChange = (option) => {
        setFilterOption(option);
    };

    const addNewClient = () => {
        console.log("Adding new client!");
        createNewClient();
    };

    const filteredClients = clients.filter((client) =>
        client.name.toLowerCase().includes(searchTerm.toLowerCase())
    ).sort((a, b) => {
        if (filterOption === 'Oldest') {
            return new Date(a.registerDate) - new Date(b.registerDate);
        } else if (filterOption === 'Newest') {
            return new Date(b.registerDate) - new Date(a.registerDate);
        }
        return 0;
    });

    return (
        <Box sx={{ padding: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
                Clients
            </Typography>
            <TextField
                label="Search clients"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                fullWidth
                sx={{
                    mb: 2,
                    '& .MuiInputLabel-root': {
                        top: '-8px', // Adjusts the label position
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#e0e0e0', // Adjust border color if needed
                        },
                        '&:hover fieldset': {
                            borderColor: '#9c27b0', // Optional hover color
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#9c27b0', // Optional focused color
                        },
                    },
                }}
            />
            <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                <Button
                    variant={filterOption === 'All' ? 'contained' : 'outlined'}
                    onClick={() => handleFilterChange('All')}
                >
                    All
                </Button>
                <Button
                    variant={filterOption === 'Oldest' ? 'contained' : 'outlined'}
                    onClick={() => handleFilterChange('Oldest')}
                >
                    Oldest
                </Button>
                <Button
                    variant={filterOption === 'Newest' ? 'contained' : 'outlined'}
                    onClick={() => handleFilterChange('Newest')}
                >
                    Newest
                </Button>
                <Button
                    variant={'outlined'}
                    onClick={() => addNewClient()}
                >
                    + New
                </Button>
            </Box>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <List>
                    {filteredClients.map((client) => (
                        <ListItem
                            key={client.uuid}
                            onClick={() => onClientSelect(client)}
                        >
                            <ListItemText
                                primary={client.name}
                                secondary={`Registered: ${client.registerDate}`}
                            />
                        </ListItem>
                    ))}
                </List>
            )}
        </Box>
    );
};

export default ClientQueue;
