import React, { useState, useEffect } from 'react';
import CompanyDetail from './components/CompanyDetail';

const Home = () => {
	const [company, setCompany] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchCompanyInfo = () => {
			setLoading(true);
			// Mock client data
			const mockCompanyData =
			{
				id: 0, name: "Arc Global", updated: "12 March 2024", gender: "Male", birthdate: "12 August 2001", phone: "+62 837 356 343 23", email: "jeromebellingham93@mail.com", 
				target_personas: [
					{ created: '12 Oct 2023', title: 'Prosthetic Tooth Fabrication'},
					{ created: '12 Sep 2023', title: 'Post-Surgical Care'},
					{ created: '12 Aug 2023', title: 'Implant Placement'},
				]
			};

			setCompany(mockCompanyData);
			setLoading(false);
		};

		fetchCompanyInfo();
	}, []);

	return (
		<CompanyDetail company={company} />
	);
};

export default Home;
