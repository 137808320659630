import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';

// Create a context
const CompanyContext = createContext();

// Custom hook to access context easily
export const useCompany = () => useContext(CompanyContext);

// Provider component to wrap around your app
export const CompanyProvider = ({ children }) => {
	const [companyId, setCompanyId] = useState(null);

	const { currentUser } = useAuth();

	useEffect(() => {
		// Fetch the company ID once when the component mounts
		const fetchCompanyId = async () => {
			if (!currentUser){
				setCompanyId(null);
				return;
			}

			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}/user/${currentUser.uid}/`, {
					method: 'GET',
					headers: {
						'Authorization': 'Bearer your_auth_token' // replace with the actual token if needed
					}
				});
				
				if (response.ok) {
					const data = await response.json();
					setCompanyId(data.company.uuid || null); // Set the company ID in state
				} else {
					console.error("Failed to fetch company ID");
				}
			} catch (error) {
				console.error("Error fetching company ID:", error);
			}
		};

		fetchCompanyId();
	}, [currentUser]); // Empty dependency array means this runs only once



	// const userContextValue = {
	// 	currentUser,
	// 	userLoggedIn,
	// 	loading,
	// };

	return (
		<CompanyContext.Provider value={{companyId}}>
			{children}
		</CompanyContext.Provider>
	);
};
