import { Outlet, BrowserRouter, Routes, Route } from "react-router-dom";
import AppTheme from 'shared-theme/AppTheme';
import CssBaseline from '@mui/material/CssBaseline';
import NavigationBar from "components/NavigationBar";
import ProtectedRoute from "components/ProtectedRoute";
import { Box } from "@mui/material";

import LandingPage from "pages/Landing/LandingPage";
import SignUp from "pages/SignUp/SignUp";
import SignIn from "pages/SignIn/SignIn";
import CreateUserProfile from "pages/Onboarding/1_CreateUserProfile";
import CreateCompanyProfile from "pages/Onboarding/2_CreateCompanyProfile";
import UploadCandidateAndClientData from "pages/Onboarding/3_UploadCandidateAndClientData";
import DemoPage from "pages/Onboarding/4_Demo";
import Home from "pages/Home/home";
import AgencyHome from "pages/Home/agencyHome";
import NoPage from "pages/ErrorResponse/NoPage";
import ConnectionMap from "pages/Landing/components/ConnectionMap";

export default function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route index element={<LandingPage />} />
				<Route path="/mock-graph" element={<ConnectionMap />} />

				<Route path="/sign-up" element={<SignUp />} />
				<Route path="/sign-in" element={<SignIn />} />

				<Route
					path="/"
					element={
						<ProtectedRoute>
							<AppTheme>
								<CssBaseline enableColorScheme />
								<NavigationBar />

								{/* Wrap Outlet in a container to apply padding */}
								<Box sx={{ marginTop: 'var(--navbar-height)', paddingTop: 8 }}>
									<Outlet />
								</Box>
							</AppTheme>
						</ProtectedRoute>
					}
				>
					<Route path="/onboarding">
						<Route index element={<CreateUserProfile />} />
						<Route path="user-profile" element={<CreateUserProfile />} />
						<Route path="company-profile" element={<CreateCompanyProfile />} />
						<Route path="upload-crm" element={<UploadCandidateAndClientData />} />
						<Route path="walkthrough" element={<DemoPage />} />
					</Route>

					<Route path="/home" element={<Home />} />
					<Route path="/agency-home" element={<AgencyHome />} />
				</Route>

				<Route path="*" element={<NoPage />} />
			</Routes>
		</BrowserRouter>
	);
}
