import { useEffect, useRef } from 'react';
import ForceGraph3D from 'react-force-graph-3d';
import { faker } from '@faker-js/faker';
import SpriteText from 'three-spritetext';
// import * as THREE from 'three';  // Import THREE for custom node labels

const ConnectionMap = () => {

    let nodes = [];

    const num_companies = 20;  // Number of companies (n)
    const num_people = 50;    // Number of people (m)
    const max_company_links = 10; // Maximum number of random bidirectional links between companies
    const max_person_links = 70;  // Maximum number of random links involving people

    // Generate nodes with fake names and colors
    for (let i = 0; i < num_companies; i++) {
        nodes.push({
            id: i,
            type: "Company",
            color: "steelblue",
            properties: { name: faker.company.name() }
        });
    }

    for (let i = num_companies; i < num_companies + num_people; i++) {
        nodes.push({
            id: i,
            type: "Person",
            color: "orange",
            properties: { name: faker.person.fullName() }
        });
    }

    // Helper function to generate random integers within a specified range
    function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    // Helper function to check if a link already exists and count existing links between nodes
    function countLinksBetweenNodes(source, target) {
        return links.filter(link => (
            (link.source === source && link.target === target) ||
            (link.source === target && link.target === source)
        )).length;
    }

    // Initialize links array
    let links = [];

    // Generate random bidirectional "COMPETES_AGAINST" and "HAS_RELATED_TECH_STACK" links between companies
    for (let i = 0; i < max_company_links; i++) {
        let companyA = getRandomInt(0, num_companies - 1);
        let companyB = getRandomInt(0, num_companies - 1);

        // Ensure companies are different and that no duplicate links are created
        while (companyA === companyB) {
            companyA = getRandomInt(0, num_companies - 1);
            companyB = getRandomInt(0, num_companies - 1);
        }

        // Determine the relationship type
        const relationship = getRandomInt(0, 1) === 0 ? "COMPETES_AGAINST" : "HAS_RELATED_TECH_STACK";

        // Count existing links between companyA and companyB
        const linkCount = countLinksBetweenNodes(companyA, companyB);
        const curvature = (linkCount % 2 === 0 ? 1 : -1) * (Math.floor(linkCount / 2) * 0.1);

        // Add bidirectional links with varying curvature
        links.push({ source: companyA, target: companyB, relationship: relationship, curvature: curvature });
        links.push({ source: companyB, target: companyA, relationship: relationship, curvature: -curvature });
    }

    // Generate random links between people and companies
    for (let i = 0; i < max_person_links; i++) {
        let personNode = num_companies + getRandomInt(0, num_people - 1);
        let companyNode = getRandomInt(0, num_companies - 1);

        // Randomly choose a relationship type between person and company
        const relationships = ["HIRED_FROM", "HAS_RELEVANT_STAFF_MEMBER", "APPLIED_TO"];
        const relationship = relationships[getRandomInt(0, relationships.length - 1)];

        // Count existing links between personNode and companyNode
        const linkCount = countLinksBetweenNodes(personNode, companyNode);
        const curvature = (linkCount % 2 === 0 ? 1 : -1) * (Math.floor(linkCount / 2) * 0.1);

        // Add the link with calculated curvature
        links.push({ source: personNode, target: companyNode, relationship: relationship, curvature: curvature });

        // If it's a bidirectional relationship, add the reverse link
        // if (relationship === "HIRED_FROM") {
        //     links.push({ source: companyNode, target: personNode, relationship: relationship, curvature: -curvature });
        // }
    }

    // Ensure all nodes have at least one connection
    const connectedNodes = new Set();
    links.forEach(link => {
        connectedNodes.add(link.source);
        connectedNodes.add(link.target);
    });

    for (let i = 0; i < num_companies + num_people; i++) {
        if (!connectedNodes.has(i)) {
            if (i < num_companies) {
                // Company node with no connections, connect to a random person
                let randomPerson = num_companies + getRandomInt(0, num_people - 1);
                links.push({ source: i, target: randomPerson, relationship: "HAS_RELEVANT_STAFF_MEMBER", curvature: 0.0 });
                connectedNodes.add(i);
                connectedNodes.add(randomPerson);
            } else {
                // Person node with no connections, connect to a random company
                let randomCompany = getRandomInt(0, num_companies - 1);
                const relationship = getRandomInt(0, 1) === 0 ? "HIRED_FROM" : "APPLIED_TO";
                links.push({ source: i, target: randomCompany, relationship: relationship, curvature: 0.0 });
                connectedNodes.add(i);
                connectedNodes.add(randomCompany);
            }
        }
    }


    const mockGraphData = {
        nodes: nodes,
        links: links
    };

    const distance = 100;

    const fgRef = useRef();

    useEffect(() => {
        fgRef.current.cameraPosition({ z: distance });

        // Optional camera orbit
        let angle = 0;
        const interval = setInterval(() => {
            fgRef.current.cameraPosition({
                x: distance * Math.sin(angle),
                z: distance * Math.cos(angle)
            });
            angle += Math.PI / 3600;
        }, 10);
        return () => clearInterval(interval);
    }, []);

    // function generateLabelCanvas(text) {
    //     const canvas = document.createElement('canvas');
    //     const context = canvas.getContext('2d');
    //     context.font = '24px Arial';
    //     const textWidth = context.measureText(text).width;
    //     canvas.width = textWidth;
    //     canvas.height = 30;
    //     context.fillStyle = 'black';
    //     context.fillText(text, 0, 20);
    //     return canvas;
    // }

    return (
        <ForceGraph3D
            ref={fgRef}
            graphData={mockGraphData}
            nodeLabel="properties.name"
            nodeColor={node => node.color}
            enableNodeDrag={false}
            enableNavigationControls={false}
            showNavInfo={false}
            backgroundColor="white"
            linkThreeObjectExtend={true}
            linkCurvature={link => link.curvature}
            linkDirectionalArrowLength={4}
            linkDirectionalArrowRelPos={1}
            linkColor={link => link.color || '#000000'}
            // nodeThreeObject={node => {
            //     // Create a sprite for displaying the label below the node
            //     const sprite = new THREE.Sprite(
            //         new THREE.SpriteMaterial({
            //             map: new THREE.CanvasTexture(
            //                 generateLabelCanvas(node.properties.name) // Call helper to generate label texture
            //             ),
            //             depthTest: false
            //         })
            //     );
            //     sprite.position.set(0, -8, 0);  // Position label below the node
            //     // sprite.scale.set(20, 10, 1);    // Adjust label size
            //     return sprite;
            // }}
            linkThreeObject={link => {
                // extend link with text sprite
                const sprite = new SpriteText(`${link.relationship}`);
                sprite.color = 'lightgrey';
                sprite.textHeight = 1.5;
                return sprite;
            }}
            linkPositionUpdate={(sprite, { start, end }) => {
                const middlePos = Object.assign(...['x', 'y', 'z'].map(c => ({
                    [c]: start[c] + (end[c] - start[c]) / 2 // calc middle point
                })));

                // Position sprite
                Object.assign(sprite.position, middlePos);
            }}
        />
    );
}

export default ConnectionMap;
