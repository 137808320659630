import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Box, Tabs, Tab } from '@mui/material';
import Spinner from 'components/Spinnner';
import ChipList from './ChipList'; // Import the new component

const ActivePersonas = ({ parent_company_uuid }) => {
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const [tabs, setTabs] = useState([]);
	const [isEditing, setIsEditing] = useState({ index: null, field: null });
	const [loading, setLoading] = useState(false);

	const CustomTabPanel = ({ children, value, index }) => {
		return (
			<div role="tabpanel" hidden={value !== index}>
				{value === index && <Box p={3}>{children}</Box>}
			</div>
		);
	};

	useEffect(() => {
		const fetchPersonas = async () => {
			setLoading(true);
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}/company/${parent_company_uuid}/personas/`, {
					method: 'GET',
					headers: {
						'Authorization': 'Bearer your_auth_token'
					}
				});

				if (response.ok) {
					const data = await response.json();
					setTabs(data.personas);
				} else {
					console.error(`Failed to fetch personas for ${parent_company_uuid}`);
				}
			} catch (error) {
				console.error(`Error fetching personas for ${parent_company_uuid}: ${error}`);
			}
			setLoading(false);
		};

		fetchPersonas();
	}, [parent_company_uuid]);

	const handleTabSelectionChange = (event, newIndex) => {
		setSelectedTabIndex(newIndex);
		setIsEditing({ index: null, field: null });
	};

	const handleAddTab = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/company/${parent_company_uuid}/personas/`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer your_auth_token' // Replace with actual token if needed
				},
				// body: JSON.stringify({
				// 	target_market: 'New Persona',
				// 	subcategories: [],
				// 	target_job_titles: [],
				// 	target_locations: []
				// })
			});

			if (response.ok) {
				const newTab = await response.json();
				setTabs((prevTabs) => [...prevTabs, newTab]); // Add new tab to tabs array using the server response
				setSelectedTabIndex(tabs.length); // Set focus to the newly added tab
			} else {
				console.error('Failed to add new persona');
			}
		} catch (error) {
			console.error('Error adding new persona:', error);
		}
	};


	const handleAddChip = async (index, field) => {
		const newChip = prompt(`Enter new ${field.slice(0, -1)}:`);
		if (newChip) {
			const updatedTabs = [...tabs];
			updatedTabs[index][field].push(newChip);
			setTabs(updatedTabs);
			await updateServer(index, field, updatedTabs[index][field]);
		}
	};

	const handleDeleteChip = async (index, chip) => {
		const updatedTabs = [...tabs];
		const field = Object.keys(updatedTabs[index]).find(f => updatedTabs[index][f].includes(chip));
		updatedTabs[index][field] = updatedTabs[index][field].filter(item => item !== chip);
		setTabs(updatedTabs);
		await updateServer(index, field, updatedTabs[index][field]);
	};

	const handleEditChip = (index, chipIndex, newLabel) => {
		const field = Object.keys(tabs[index]).find(f => tabs[index][f].includes(tabs[index][f][chipIndex]));
		const updatedTabs = [...tabs];
		updatedTabs[index][field][chipIndex] = newLabel;
		setTabs(updatedTabs);
		// Update server if needed
	};

	const updateServer = async (index, field, data) => {
		try {
			await fetch(`${process.env.REACT_APP_API_URL}/company/${parent_company_uuid}/personas/${field}`, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer your_auth_token',
				},
				body: JSON.stringify(data),
			});
		} catch (error) {
			console.error(`Error updating ${field} for persona ${index}:`, error);
		}
	};

	return (
		<Card>
			<CardContent>
				<Typography variant="h6">Target Audiences</Typography>
				{loading ? <Spinner /> :
					<Box>
						<Tabs value={selectedTabIndex} onChange={handleTabSelectionChange} variant="scrollable" scrollButtons="auto">
							{tabs.map((tab, index) => (
								<Tab key={index} label={tab.target_market} />
							))}
							<Tab label="+" onClick={() => handleAddTab()} />
						</Tabs>


						{tabs.map((tab, index) => (
							<CustomTabPanel value={selectedTabIndex} index={index} key={index}>
								<Box key={index}>
									<Typography variant="subtitle1">Subcategories:</Typography>
									<ChipList
										items={tab.subcategories}
										onEdit={handleEditChip}
										onDelete={handleDeleteChip}
										onAdd={handleAddChip}
										isEditing={isEditing}
										setIsEditing={setIsEditing}
										field="subcategories"
										index={index}
									/>
									<Typography variant="subtitle1">Target Job Titles:</Typography>
									<ChipList
										items={tab.target_job_titles}
										onEdit={handleEditChip}
										onDelete={handleDeleteChip}
										onAdd={handleAddChip}
										isEditing={isEditing}
										setIsEditing={setIsEditing}
										field="target_job_titles"
										index={index}
									/>
									<Typography variant="subtitle1">Target Locations:</Typography>
									<ChipList
										items={tab.target_locations}
										onEdit={handleEditChip}
										onDelete={handleDeleteChip}
										onAdd={handleAddChip}
										isEditing={isEditing}
										setIsEditing={setIsEditing}
										field="target_locations"
										index={index}
									/>
								</Box>
							</CustomTabPanel>
						))}
					</Box>
				}
			</CardContent>
		</Card>
	);
};

export default ActivePersonas;
