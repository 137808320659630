import React from 'react';
import { Card, CardContent, Typography, List, ListItem, Box, Stack } from '@mui/material';
import ActivePersonas from './ActivePersonas';
import SimilarCompanies from './SimilarCompanies';

const CompanyDetail = ({ company }) => {

    return (
        <Box>
            <Stack spacing={2}>
                <Card>
                    <CardContent>
                        <Typography variant="h5">{company.name}</Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                            Last Updated: {company.updated}
                        </Typography>
                    </CardContent>
                </Card>

                <Card>
                    <CardContent>
                        <Typography variant="h6">Basic Informational</Typography>
                        <List>
                            <ListItem>Employees: 123</ListItem>
                            <ListItem>Specialisation: Marketing</ListItem>
                            <ListItem>Contact name: Jim</ListItem>
                            <ListItem>Contact Email: jim.bo@{company.name}.com</ListItem>
                        </List>
                    </CardContent>
                </Card>
           
                <ActivePersonas parent_company_uuid={company.uuid}/>
                <SimilarCompanies/>
            </Stack>
        </Box>
    );
};

export default CompanyDetail;
