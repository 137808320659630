import React, { useState, useEffect } from 'react';
// import Grid from '@mui/material/Grid2';
import { Grid } from '@mui/material';
import ClientQueue from './components/ClientQueue';
import CompanyDetail from './components/CompanyDetail';
import { useCompany } from 'contexts/CompanyContext';

const AgencyHome = () => {
	const [clients, setClients] = useState([]);
	const [selectedClient, setSelectedClient] = useState(null);
	const [loading, setLoading] = useState(true);

    const { companyId } = useCompany();

	useEffect(() => {
		const fetchClients = () => {
			setLoading(true);
			// Mock client data
			const mockClientData = [
				{
					uuid: "91582056-b781-4986-b80c-906ea375b999", name: "Arc Global", updated: "12 March 2024", gender: "Male", birthdate: "12 August 2001", phone: "+62 837 356 343 23", email: "jeromebellingham93@mail.com", 
					target_personas: [
						{ created: '12 Oct 2023', title: 'Prosthetic Tooth Fabrication'},
						{ created: '12 Sep 2023', title: 'Post-Surgical Care'},
						{ created: '12 Aug 2023', title: 'Implant Placement'},
					]
				},
				{ uuid: "f0503817-b60e-461f-b2b6-7913d666d382", name: "Reviva", updated: "14 March 2024" },
				{ uuid: "b1da942c-361e-402d-8719-264abfe1cb41", name: "Atheno AI", updated: "18 March 2024" },
				{ uuid: "6200841f-4bb0-4886-b9a5-a385abf9461b", name: "On The Box", updated: "2 March 2024" },
				{ uuid: "630312a8-9269-4da2-9321-c385fcb84f88", name: "Clouvie", updated: "27 March 2024" },
			];

			setClients(mockClientData);
			setSelectedClient(mockClientData[0]); // Select the first client by default
			setLoading(false);
		};

		fetchClients();
	}, []);

	const createNewClient = () => {
		// Create a new client object
		const newClient = {
			id: clients.length,
			name: "New Client",
			updated: "Today",
		};

		// Update clients state with the new client
		setClients([...clients, newClient]);
	};

	const handleClientSelect = (client) => {
		setSelectedClient(client);
	};

	return (
		<Grid container sx={{ height: '100vh' }}>
			<Grid item xs={3} sx={{ borderRight: '1px solid #e0e0e0' }}>
				<ClientQueue clients={clients} loading={loading} onClientSelect={handleClientSelect} createNewClient={createNewClient} />
			</Grid>
			<Grid item xs={9} sx={{ padding: 2 }}>
				{selectedClient ? (
					<CompanyDetail company={selectedClient} />
				) : (
					<div>Select a client to view details</div>
				)}
			</Grid>
		</Grid>
	);
};

export default AgencyHome;
