import { createContext, useContext, useEffect, useState } from 'react';
import { auth } from 'remote/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import Spinner from 'components/Spinnner';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true); 

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, initialiseUser);
        return unsubscribe;
    }, []);

    async function initialiseUser(user) {
        setLoading(true);

        if (user) {
            setCurrentUser({ ...user });
            setUserLoggedIn(true);
        } else {
            setCurrentUser(null);
            setUserLoggedIn(false);
        }

        setLoading(false);
    }

    const authContextValue = {
        currentUser,
        userLoggedIn,
        loading,
    };

    return (
        <AuthContext.Provider value={authContextValue}>
            {loading ? <Spinner /> : children}
        </AuthContext.Provider>
    );
}
