// ChipList.js
import React from 'react';
import { Box, Chip, TextField, Tooltip } from '@mui/material';

const ChipList = ({ items, onEdit, onDelete, onAdd, isEditing, setIsEditing, field, index }) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginBottom: 2 }}>
      {items.map((chip, chipIndex) => (
        <Tooltip title="Double click to edit" key={chipIndex}>
          <Chip
            label={isEditing.index === index && isEditing.field === field && isEditing.chipIndex === chipIndex ? (
              <TextField
                defaultValue={chip}
                onBlur={() => setIsEditing({ index: null, field: null, chipIndex: null })}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onEdit(index, chipIndex, e.target.value);
                    setIsEditing({ index: null, field: null, chipIndex: null });
                  }
                }}
                autoFocus
              />
            ) : (
              chip
            )}
            onDelete={() => onDelete(index, chip)}
            onDoubleClick={() => setIsEditing({ index, field, chipIndex })}
          />
        </Tooltip>
      ))}
      <Chip label="+" onClick={() => onAdd(index, field)} sx={{ backgroundColor: 'green', color: 'white' }} />
    </Box>
  );
};

export default ChipList;
