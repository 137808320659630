import React, { useState, useRef, useEffect, /*useCallback*/ } from 'react';
import ForceGraph2D from 'react-force-graph-2d';


export default function GraphView({ graphData }) {

	const graphRef = useRef();
	// const purple = '#8e6bee'
	const grey = '#5c5c5c'
	const lightGrey = '#f0f0f0'

	const [selectedNode, setSelectedNode] = useState(null);

	const wrapText = (text, maxLineLength, maxLines = 3) => {
		const words = text.split(' '); // Safely split string into words
		const lines = [];
		let currentLine = '';
	
		words.forEach(word => {
			// Check if the current word is longer than maxLineLength
			while (word.length > maxLineLength) {
				// If the current line is not empty, push it to lines
				if (currentLine) {
					lines.push(currentLine);
					currentLine = '';
				}
				// Push a substring of the word with maxLineLength
				lines.push(word.slice(0, maxLineLength));
				// Update the word to remove the processed part
				word = word.slice(maxLineLength);
			}
	
			const testLine = currentLine ? currentLine + ' ' + word : word;
	
			if (testLine.length <= maxLineLength) {
				currentLine = testLine;
			} else {
				if (currentLine) {
					lines.push(currentLine);
					currentLine = '';
				}
				currentLine = word;
			}
	
			// Stop adding new lines if we've reached the maximum number of lines
			if (lines.length >= maxLines) {
				return;
			}
		});
	
		// Add any remaining text in currentLine if the maximum number of lines isn't reached
		if (currentLine && lines.length < maxLines) {
			lines.push(currentLine);
		}
	
		// Return only up to maxLines lines
		return lines.slice(0, maxLines);
	};

    useEffect(() => {
		const forceGraph = graphRef.current;
        if (forceGraph) {
            forceGraph.d3Force("charge").strength(-5000);
            forceGraph.d3Force("link").distance(250);
			forceGraph.d3Force("center")
                .x(0)
                .y(0)
				.strength(1);
        }
    }, [graphRef]);

	const renderNode = (node, ctx, globalScale) => {
		const labelFieldMapping = {
			"NetworkingHub": "title",
			"Person": "name",
			"Company": "name",
			"Event": "title",
			"Meeting": "title",
			"School": "name",
			"Location": "address",
			"Tag": "tag",
			"Article": "headline",
			"EmployeeCount": "count",
			"PainPoint": "pain",
			"Intent": "action",
			"Skill": "skill",
			"Topic": "topic",
			"Specialty": "specialty",
			"Industry": "industry"
		}[node.type];

		const label = node.properties[labelFieldMapping] || node.id;

        const baseColour = {
			"NetworkingHub": "#5ae5a7",
			"Person": "#C990C0",
			"Company": "#ffbfb5",
			"Event": "#b5e6fe",
			"Meeting": "#4C8EDA",
			"School": "#A7BCA3",
			"Location": "#8080ff",
			"Tag": "#FF00FF",
			"Article": "#00FFFF",
			"EmployeeCount": "#ffff00",
			"PainPoint": "#F54021",
			"Intent": "#EFA94A",
			"Skill": "#308446",
			"Topic": "rgb(174,211,96)",
			"Specialty": "#6ee8e2",
			"Industry": "#c5ddb5"
		}[node.type] || grey;

		const radius = 30;
		const maxLines = 3;
		const fontSize = Math.min(12, radius * 0.3);
		ctx.font = `${fontSize}px Sans-Serif`;
	
		const maxLineLength = 10;
		const lines = wrapText(label, maxLineLength);
	
		// If more text than can fit, truncate the last line
		if (lines.length === maxLines && label.length > maxLineLength * maxLines) {
		  lines[maxLines - 1] = lines[maxLines - 1].slice(0, -3) + '...';
		}
	
		ctx.beginPath();
		ctx.arc(node.x, node.y, radius, 0, 2 * Math.PI, false);

		var shouldHighlight = true;
		if (selectedNode && (node !== selectedNode && !isConnected(selectedNode, node)))
			shouldHighlight = false
		
		ctx.fillStyle = shouldHighlight ? baseColour : lightGrey;

		ctx.fill();
	
		const totalTextHeight = lines.length * fontSize;
		const textYStart = node.y - totalTextHeight / 2 + fontSize/2;
	
		ctx.fillStyle = shouldHighlight ? "black" : grey;
		ctx.textAlign = "center";
		ctx.textBaseline = "middle";
	
		lines.forEach((line, i) => {
		  const lineY = textYStart + i * fontSize;
		  ctx.fillText(line, node.x, lineY);
		});
	}
	
	const isConnected = (node, neighbor) => {
		return graphData.links.some(link =>
			(link.source === node && link.target === neighbor) ||
			(link.source === neighbor && link.target === node)
		);
	};

	const renderLink = (link, ctx, globalScale) => {
        const MAX_FONT_SIZE = Math.min(14, Math.max(4, 14 / globalScale));
        const LABEL_NODE_MARGIN = 5;
        const curvature = link.curvature || 0.0; // Curvature value for each link, set dynamically if available
    
        const start = link.source;
        const end = link.target;
    
        // Control point to adjust the midpoint for a curved link
        const controlPoint = {
            x: (start.x + end.x) / 2 + curvature * (end.y - start.y),
            y: (start.y + end.y) / 2 - curvature * (end.x - start.x)
        };
    
        // Calculate position and angle for the label along the curve
        const t = 0.5; // Midpoint along the curve
        const textX = (1 - t) * (1 - t) * start.x + 2 * (1 - t) * t * controlPoint.x + t * t * end.x;
        const textY = (1 - t) * (1 - t) * start.y + 2 * (1 - t) * t * controlPoint.y + t * t * end.y;
    
        // Tangent angle at the midpoint for text rotation
        const dx = (1 - t) * (controlPoint.x - start.x) + t * (end.x - controlPoint.x);
        const dy = (1 - t) * (controlPoint.y - start.y) + t * (end.y - controlPoint.y);
        let textAngle = Math.atan2(dy, dx);
    
        // Flip text if upside-down for better readability
        if (textAngle > Math.PI / 2 || textAngle < -Math.PI / 2) {
            textAngle += Math.PI;
        }
    
        // Draw the label
        const label = link.relationship || "";
        ctx.font = `${MAX_FONT_SIZE}px Sans-Serif`;
        ctx.save();
        ctx.translate(textX, textY);
        ctx.rotate(textAngle);
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
    
        // Apply styling for selected nodes
        ctx.fillStyle = !selectedNode || (selectedNode === link.source || selectedNode === link.target) ? grey : lightGrey;
        ctx.fillText(label, 0, -LABEL_NODE_MARGIN); // Adjust margin as needed
        ctx.restore();
    };
    
    

	const renderLinkColour = (link) => {
		if (!selectedNode || (selectedNode && (selectedNode === link.source || selectedNode === link.target)))
			return grey;
		else
			return lightGrey;
	}

	const handleLinkHover = (link) => {
		// TODO: Create tooltip with full link information
	}

	const handleNodeHover = (node) => {
		setSelectedNode(node)
	}

	const handleNodeRightClick = (node) => {
        console.log("Left click on node", node)
	}

	const [prevClick, setPrevClick] = useState();
	const handleNodeLeftClick = (node) => {
		const DBL_CLICK_TIMEOUT = 500; // ms
		const now = new Date();
		if (prevClick && prevClick.node === node && (now - prevClick.time) < DBL_CLICK_TIMEOUT) {
			setPrevClick(null);
			handleNodeDoubleClick(node);
		}
		else {
			handleNodeSingleClick(node)
		}
		setPrevClick({ node, time: now });
	};

	const handleNodeSingleClick = (node) => {
        console.log("Single click on node", node)
    }

	const handleNodeDoubleClick = (node) => {
        console.log("Double click on node", node)
    }

	const handleBackgroundRightClick = () => {
		console.log("Right click on background")
	}

	return (
		<ForceGraph2D
			ref={graphRef}
			graphData={graphData}
			nodeRelSize={30}
            backgroundColor={"#ffffff"}

			nodeCanvasObject={renderNode}

			linkCanvasObjectMode={() => 'after'}
			linkCanvasObject={renderLink}
			linkColor={renderLinkColour}
			linkDirectionalArrowLength={10}
            linkDirectionalArrowRelPos={1}
			onLinkHover={handleLinkHover}
            linkCurvature="curvature"

			onNodeHover={handleNodeHover}
			onNodeClick={handleNodeLeftClick}
			onNodeRightClick={handleNodeRightClick}
			onBackgroundRightClick={handleBackgroundRightClick}
		/>
	);
};
