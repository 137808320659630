import * as React from 'react';
import { Box, Typography } from '@mui/material';

export default function SitemarkIcon() {
	return (
		<Box
			sx={{
				backgroundImage: 'linear-gradient(to right, #FF5733, #33A1FF)', // Replace with your primary and secondary colors
				WebkitBackgroundClip: 'text',
				backgroundClip: 'text',
				color: 'transparent',
				fontSize: '3em', // Adjust font size as needed
				fontWeight: 'bold',
				margin: '8px 16px'
			}}
		>
			<Typography>
				TWO DEGREES
			</Typography>
		</Box>
	);
}