import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Sitemark from './SitemarkIcon';
import ColorModeIconDropdown from 'shared-theme/ColorModeIconDropdown';
import { Link } from "react-router-dom";
import { useAuth } from 'contexts/AuthContext';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexShrink: 0,
    height: 'var(--navbar-height)',  // Match CSS variable for consistent spacing
    borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
    backdropFilter: 'blur(24px)',
    border: '1px solid',
    borderColor: (theme.vars || theme).palette.divider,
    backgroundColor: theme.vars
      ? `rgba(${theme.vars.palette.background.defaultChannel} / 0.4)`
      : alpha(theme.palette.background.default, 0.4),
    boxShadow: (theme.vars || theme).shadows[1],
    padding: '8px 12px',
}));

export default function NavigationBar() {
    const [open, setOpen] = React.useState(false);
    const { currentUser } = useAuth();

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    return (
      <AppBar
          position="fixed"
          sx={{
            height: 'var(--navbar-height)',
            boxShadow: 0,
            bgcolor: 'transparent',
            backgroundImage: 'none',
          }}
      >
          <Container maxWidth="lg">
              <StyledToolbar variant="dense" disableGutters>
                  <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0 }}>
                      <Sitemark />
                  </Box>
                  <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 1, alignItems: 'center' }}>
                      <Button color="primary" variant="text" size="small">
                          <Link to={'/profile'}>{currentUser.displayName}</Link>
                      </Button>
                      <ColorModeIconDropdown />
                  </Box>
                  <Box sx={{ display: { xs: 'flex', md: 'none' }, gap: 1 }}>
                      <ColorModeIconDropdown size="medium" />
                      <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
                          <MenuIcon />
                      </IconButton>
                      <Drawer
                          anchor="top"
                          open={open}
                          onClose={toggleDrawer(false)}
                          PaperProps={{
                            sx: {
                              top: 'var(--navbar-height)',
                            },
                          }}
                      >
                          <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                              <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                  }}
                              >
                                  <IconButton onClick={toggleDrawer(false)}>
                                      <CloseRoundedIcon />
                                  </IconButton>
                              </Box>

                              <Divider sx={{ my: 3 }} />
                              <MenuItem>
                                  <Button color="primary" variant="contained" fullWidth>
                                      <Link to="/profile">{currentUser.displayName}</Link>
                                  </Button>
                              </MenuItem>
                          </Box>
                      </Drawer>
                  </Box>
              </StyledToolbar>
          </Container>
      </AppBar>
    );
}
